.signedMessage {
  .certifies {
    display: flex;
  }

  .bullet {
    color: #49ff4c;
    font-size: 0.7rem;
    margin-top: 1.1rem;
    margin-right: 0.5rem;
  }

  p {
    max-width: 100%;
    overflow-wrap: break-word;
  }
}

@media only screen and (max-width: 460px) {
  .bullet {
    display: none;
  }
}

@media print {
  .bullet {
    display: none;
  }
}
