.navBar {
  a {
    color: #000000;
    text-decoration: none;
  }

  ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0.8rem 0;
    border-bottom: 1px solid #eaecef;
    justify-content: space-between;
  }

  li {
    list-style: none;
  }

  .walletSignature {
    font-weight: bold;
    font-size: 1.3rem;
    color: #2c3e50;
  }
}
