html {
  background-color: #f7f7f7;
  padding: 0 1rem;
}

main {
  max-width: 60rem;
  margin: auto;
}

input[type="text"],
textarea {
  font-size: 1rem;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  border: 1px solid #eaecef;
  resize: vertical;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border: none;
  margin: 1rem 0;
  cursor: pointer;
  color: #ffffff;
  background-color: #000000;
}

button.link {
  background-color: transparent;
  color: #000000;
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
  border: none;
}

label {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

hr {
  border-top: 1px solid #eaecef;
}

@media print {
  html,
  body {
    height: 100vh; /* Use 100% here to support printing more than a single page*/
    margin: 10px !important;
    padding: 10px !important;
    overflow: hidden;
  }
}
